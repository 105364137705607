<template>
	<div class="bindLogis">
		<div class="Title" style="padding: 15px 0 15px 15px;">
			<!-- //绑定打印员 -->
			<el-button type="primary" size="small" @click="bindperson">绑定打印员</el-button><span style="margin-left: 15px;">若使用微信打单软件，请在该页面绑定打印员</span>
		</div>
		<div class="table-container" style="padding:20px;">
			<el-table :data="tableData" v-loading="loading" border style="width: 100%">
				<el-table-column prop="WxNickName" label="打印员">
				</el-table-column>
				<el-table-column prop="AddTime" label="绑定时间"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="getdeletePrinter(scope.row.Id)" style="color: red;">删除打印员</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right;">
				<el-pagination v-if="total" style="margin-top:20px;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="total">
				</el-pagination>
			</div>
		</div>
		<door-code :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeCancel" :Num='number'></door-code>
	</div>
</template>

<script>
	import config from '@/config/index'
	import doorCode from "@/components/doorCode"
	import {
		printerlist,
		deletePrinter
	} from '@/api/TurnTomySelf'
	export default {
		components: {
			doorCode
		},
		data() {
			return {
				//绑定打印员
				number: 1,
				weiCodeShow: false,
				weiCodeData: {},
				loading: false,
				tableData: [],
				currentPage: 1,
				pageSize: 20,
				total: 0,
			}
		},
		created() {
			this.getList()
		},
		methods: {
			// table删除
			getdeletePrinter(id) {
				this.$confirm('是否确认删除打印员？删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					try {
						console.log('没有执行这里吗')
						let result = await deletePrinter({
							Id: id
						})
						if (result.IsSuccess) {
							this.getList()
							this.$message({
								showClose: true,
								type: 'success',
								message: '删除成功!'
							});
						}
					} catch (err) {
						this.$message({
							showClose: true,
							type: 'error',
							message: result.Message
						});
					} finally {

					}

				}).catch(() => {
					console.log('为什么会执行这里')
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				})

			},
			weiCodeCancel(value) {
				//执行了吗
				console.log('执行了吗8888')
				this.weiCodeShow = value;
				this.getList()
			},
			bindperson() {
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: `pageA/pages/auth/auth`,
					Scene: `1`
				}
			},
			async getList() {
				try {
					this.loading = true
					let data = {
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await printerlist(data)
					console.log(result, '列表结果')
					this.total = result.Result.Total;
					this.tableData = result.Result.Results || [];
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less">
	.bindLogis {
		background: #fff;
		font-size: 14px;
	}
</style>
