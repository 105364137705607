<template>
	<el-dialog :title="Num==1?'绑定打印员':'上门自提码'" :visible.sync="weiCodeIshow" width="500px" @closed="comeback">
		<div v-loading="codeding">
			<div v-if="Num==1">请让负责打单的员工，使用微信扫码下方二维码，并在手机上完成绑定</div>
			<!-- <div class="bgColor" style="text-align: center;padding:20px 0;background:#409EFF;color: #fff;"> -->
			<!-- <div style="color: #fff;font-size: 17px;margin-bottom:10px;">扫一扫提货</div>
				<div style="color: #fff;font-size: 13px;">{{ShopmallName}}</div> -->
			<!-- <el-input v-model="inputValue" style="width:300px" disabled></el-input>
							<el-button v-clipboard:copy="inputValue" v-clipboard:success="onCopy" v-clipboard:error="onError">
								复制
							</el-button>
				-->
			<img :src="imgUrl+codePicture" alt="" id="codeId" style="width:300px;display: block;margin: 20px auto 0">
			<!-- </div> -->
			<!-- <div class="ThirdMallname" style="text-align: center;margin-top: 15px;font-size: 16px;font-weight: bold;">{{ShopthirdName}}</div> -->
			<div style="text-align:center;margin-top: 25px;">
				<span v-if='Num==0' style="color:#409EFF;cursor:pointer;padding:20px 0;display: inline-block; " @click="downloadIamge('img',codePicture)">下载小程序码</span>
				<div v-if='Num==1' class="successPriter" @click="comeback">打印员授权成功后，点击此处确认授权信息</div>
				<!-- <input type="button" id="btnsavaImg" value="下 载" @click="downloadIamge('img',codePicture)" /> -->

				<!-- <el-button size="small" @click="StorecodeVisivble = false">关 闭</el-button> -->
			</div>
		</div>
	</el-dialog>

</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import apiList from '@/api/other';
	import config from '@/config/index'
	export default {
		props: {
			Num: {
				type: Number,
			},
			weiCodeData: {
				type: Object
			},
			weiCodeVisivble: {
				type: Boolean,
				default: () => {
					return false
				}
			}

		},
		computed: {
			...mapGetters([
				'ShopmallName',
				'ShopthirdName'
			])
		},
		created() {
			this.weiCodeIshow = this.weiCodeVisivble
			this.getShopmallName()
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				codePicture: 'https://cdn.dkycn.cn/melyshop/image/20191222-492d658e-4654-4778-a097-9e439e9de307.png',
				inputValue: '',
				weiCodeIshow: false,
				codeding: false,
			}
		},
		watch: {
			weiCodeVisivble(n, o) {
				this.weiCodeIshow = n
			},
			weiCodeData(n, o) {
				this.inputValue = this.weiCodeData.Scene ? `${this.weiCodeData.Page}?${this.weiCodeData.Scene}` :
					`${this.weiCodeData.Page}`
				this.getData()

			},
		},
		methods: {
			getShopmallName() {
				let data = {}
				this.$store.dispatch('GetPcShopInfo', data).then(() => {

				}).catch((e) => {})
			},
			async getData() {
				try {
					this.codeding = true
					let data = {
						Page: this.weiCodeData.Page,
						Scene: this.weiCodeData.Scene,
						IsLimited: false,
						ProgramType: 0,
					}
					let result = await apiList.mallGetQrCode(data)
					this.codePicture = JSON.parse(result.Result)[0]
				} catch (e) {
					console.log('e', e)
				} finally {
					this.codeding = false
				}
			},
			comeback() {
				this.$emit('cancel', false)
			},
			downloadIamge(selector, name) {
				var image = new Image()
				// 解决跨域 Canvas 污染问题 解决跨域问题-并不好使，需要改对应的请求服务器
				image.setAttribute('crossOrigin', 'anonymous')
				image.onload = function() {
					var canvas = document.createElement('canvas')
					canvas.width = image.width
					canvas.height = image.height

					var context = canvas.getContext('2d')
					context.drawImage(image, 0, 0, image.width, image.height)
					var url = canvas.toDataURL('image/png')

					// 生成一个a元素
					var a = document.createElement('a')
					// 创建一个单击事件
					var event = new MouseEvent('click')

					// 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
					a.download = name || '下载图片名称'
					// 将生成的URL设置为a.href属性
					a.href = url
					// 触发a的单击事件
					a.dispatchEvent(event)
				}

				image.src = document.getElementById('codeId').src


			},
		}
	}
</script>

<style lang="less">
	.successPriter {
		background: #409EFF;
		color: #fff;
		width: 75%;
		margin: 0px auto;
		padding: 8px 0;
		border-radius: 3px;
		cursor: pointer;
	}

	#btnsavaImg {
		color: #FFF;
		background-color: #409EFF;
		border-color: #409EFF;
		display: inline-block;
		line-height: 1;
		white-space: nowrap;
		border: 0;
		cursor: pointer;
		-webkit-appearance: none;
		text-align: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		outline: 0;
		margin: 0;
		-webkit-transition: .1s;
		transition: .1s;
		font-weight: 500;
		padding: 9px 15px;
		font-size: 14px;
		border-radius: 4px;
		margin: 20px 0;
	}
</style>
